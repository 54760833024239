import MainTitle from '../components/main-title/MainTitle';
import React, { useEffect } from 'react';
// import Instagram from '../icons/instagram.svg';
// import YouTube from '../icons/youtube.svg';
// import SoundCloud from '../icons/soundcloud.svg';
// import Mail from '../icons/email.svg';
import Page from '../components/page/Page';
import Footer from '../components/footer/Footer';
import Spotify from '../music-icons/spotify.svg';
import YouTubeMusic from '../music-icons/youtubeMusic.svg';
import Deezer from '../music-icons/deezer.svg';
import Amazon from '../music-icons/amazon.svg';

// const BUTTONS = [
//     {
//         id: '001',
//         title: 'Instagram',
//         url: 'https://www.instagram.com/podorva.band/',
//         imageSrc: Instagram,
//     },
//     {
//         id: '002',
//         title: 'YouTube',
//         url: 'https://www.youtube.com/channel/UCKU4Hil835qDDL-u3n1kgWA',
//         imageSrc: YouTube,
//     },
//     {
//         id: '003',
//         title: 'SoundCloud',
//         url: 'https://soundcloud.com/podorva',
//         imageSrc: SoundCloud,
//     },
//     {
//         id: '004',
//         title: 'Mail',
//         url: 'mailto:podorva.official@gmail.com',
//         imageSrc: Mail,
//     },
// ]
//
// const BUTTONS_MUSIC = [
//     {
//         title: 'spotify',
//         color: 'green',
//     },
//     {
//         title: 'youtube',
//         color: 'red',
//     },
//     {
//         title: 'deezer',
//         color: 'coral',
//     },
//     {
//         title: 'amazon',
//         color: 'blue',
//     },
// ]

const ALBUMS = [
    {
        title: 'Ліс',
        id: 'lis',
        url: '/lis',
        imageSrc: require('../images/albums/lis.jpg'),
        imageMobileSrc: require('../images/albums/lis_mobile.png'),
        alt: 'Ліс Подорва',
        audioSrc: 'https://open.spotify.com/embed/album/3alAGRg5ysOKyXtikZjLJM?utm_source=generator',
        color: 'yellow',
        urls: {
            spotify: 'https://open.spotify.com/album/5T0kSAo6kviPdlr0f9xfoG',
            youtube: 'https://music.youtube.com/watch?v=XIDrzG5XWqs',
            deezer: 'https://deezer.page.link/r2NJEHZN9sy1qksY9',
            amazon: 'https://music.amazon.com/tracks/B0CR4FK98M?marketplaceId=ATVPDKIKX0DER&musicTerritory=US&ref=dm_sh_HhDyiCukShYElruGGThdeTaZR',
        },
        description: 'Життєствердна пісня про радіоактивний Рудий Ліс,\n' +
            '                            звіряток - жителів лісу та мертвих кацапів.',
        backgroundImgSrc: require('../images/lis_bc.svg'),
        backgroundColor: 'brown',
    },
    {
        title: 'Осінь',
        id: 'osin',
        url: '/osin',
        imageSrc: require('../images/albums/osin.jpg'),
        imageMobileSrc: require('../images/albums/osin_mobile.png'),
        alt: 'Осінь Подорва',
        audioSrc: 'https://open.spotify.com/embed/album/51LOOUKXgbYD9wGvPoaIIG?utm_source=generator',
        color: 'black',
        urls: {
            spotify: 'https://open.spotify.com/album/51LOOUKXgbYD9wGvPoaIIG',
            youtube: 'https://music.youtube.com/watch?v=wClC0mrjwY8&si=gQ_sdI2z0w-h1irh',
            deezer: 'https://deezer.page.link/VwG38NR3oc8zV1C88',
            amazon: 'https://music.amazon.com/albums/B0CNFW72XH?marketplaceId=ATVPDKIKX0DER&musicTerritory=US&ref=dm_sh_JLOwmpu2Jvr7XbXavbiVuYveE',
        },
        description: 'Крики про поганий дизайн, вуличні вивіски та трошки про осінь.',
        backgroundImgSrc: require('../images/osin_town.svg'),
        backgroundColor: 'white',
    },
]

const MUSIC_BUTTONS = [
    {
        id: '000',
        type: 'spotify',
        src: Spotify,
    },
    {
        id: '001',
        type: 'youtube',
        src: YouTubeMusic
    },
    {
        id: '002',
        type: 'deezer',
        src: Deezer
    },
    {
        id: '003',
        type: 'amazon',
        src: Amazon
    },
]

const MusicButtons = ({ urls, title }) => {
    return (
        <div className={'musicButtonsContainer'}>
            {
                MUSIC_BUTTONS.map((button) => {
                    return (
                        <a
                            key={button.id}
                            // className={'SocialMediaButton'}
                            href={urls[button.type]}
                            target={'_blank'}
                            rel={'noopener noreferrer'}
                        >
                            <img
                                className={'musicIcon'}
                                alt={`Подорва "${title}" - ${button.type}`}
                                // className={button.src}
                                src={button.src}
                            />
                        </a>
                    );
                })
            }
        </div>
    )
}

const Album = ({
                   id,
                   alt,
                   audioSrc,
                   color,
                   description,
                   imageSrc,
                   imageMobileSrc,
                   urls,
                   title,
                   backgroundColor,
                   backgroundImgSrc,
               }) => {
    const isMobile = window.innerWidth < 1000;
    const imageSource = isMobile ? imageMobileSrc : imageSrc;
    return (
        <Page id={id} className={`Page ${color}`}>
            {
                backgroundImgSrc &&
                <img
                    className={`backgroundImage ${backgroundColor}`}
                    alt={'Ілюстрація'}
                    src={backgroundImgSrc}>
                </img>
            }
            <div className={'albumWrapper'}>
                <div className={'albumTopBlock'}>
                    <div className={'albumCoverBlock'}>
                        {/*<div className="coverShadow"></div>*/}
                        <img alt={alt} className={'albumCover'} src={imageSource}></img>
                        <h3 className="blockDescTitleCover">{title}</h3>
                    </div>
                    <div className={`albumInfoBlock ${color}`}>
                        <h3 className={'blockDescTitle'}>{title}</h3>
                        <p className={'blockDesc'}>{description}</p>
                        <div className={'audioContainer'}>
                            <iframe
                                title={title}
                                className={'spotify'}
                                src={audioSrc}
                                frameBorder="0" allowFullScreen=""
                                allow="clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                                loading="lazy">
                            </iframe>
                        </div>
                        <MusicButtons urls={urls} title={title}/>
                    </div>
                </div>
            </div>
        </Page>
    )
}

const MainPage = () => {
    useEffect(() => {
        const url = window?.location?.href || '';
        const pageSplit = url.split('/');
        const page = pageSplit[pageSplit.length - 1] || '';
        const id = page.split('?')[0];
        if (id) {
            const element = document.getElementById(id);
            if (element) {
                element.scrollIntoView();
            }
        }
        console.log('init', id);
    }, []);
    return (
        <>
            <Page className={'Page one'}>
                <MainTitle/>
                <Footer/>
            </Page>
            {
                ALBUMS.map((album) => {
                    return (
                        <Album key={album.id} {...album}></Album>
                    )
                })
            }
            <Page id={'kyivpas'} className={'Page red'}>
                <article className={'mainContent'}>
                    <h3 className={'blockDescTitle video'}>Київ-Пас</h3>
                    <iframe className={'videoFrame'}
                            width="560" height="315" src="https://www.youtube.com/embed/ywNIOeNXORc?si=TbYPrWof76t_oc1N"
                            title="Подорва - Київ-Пас" frameBorder="0"
                            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen>

                    </iframe>
                    <p className={'blockDesc video'}>"Київ-Пас".
                        Присвячується електропотягу "Коростень - Київ-Пасажирський",
                        Укрзалізниці та студентам із периферії.</p>
                </article>
            </Page>
        </>
    )
}

export default MainPage;
