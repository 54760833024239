import React from 'react';
import '../../../App.css';
// import Stroke1 from '../../../images/svg/SVG/Asset_1.svg';
// import Stroke2 from '../../../images/svg/SVG/Asset_2.svg';
// import Stroke3 from '../../../images/svg/SVG/Asset_3.svg';
// import Stroke4 from '../../../images/svg/SVG/Asset_4.svg';
// import Stroke5 from '../../../images/svg/SVG/Asset_5.svg';
//
// import button1 from '../../../images/buttons/button_1.svg';
// import button2 from '../../../images/buttons/button_2.svg';
// import button3 from '../../../images/buttons/button_3.svg';
// import button4 from '../../../images/buttons/button_4.svg';
// import button5 from '../../../images/buttons/button_5.svg';

// const strokes = {
//     stroke1: Stroke1,
//     stroke2: Stroke2,
//     stroke3: Stroke3,
//     stroke4: Stroke4,
//     stroke5: Stroke5,
// }
//
// const strokes2 = {
//     stroke1: button1,
//     stroke2: button2,
//     stroke3: button3,
//     stroke4: button4,
//     stroke5: button5,
// }

const SocialMediaButton = ({
                               // type,
                               filter,
                               // index,
                               src,
                               href = '#',
                               alt,
                               // mainClassName,
                               // title,
                               isWhite
                           }) => {
    const svgColoredClass = filter ? `SocialMediaButtonImage ${filter}` : 'SocialMediaButtonImage';
    const svgWhiteClass = 'SocialMediaButtonImage white';
    const svgClassName = isWhite ? svgWhiteClass : svgColoredClass
    // const backs = type === 0 ? strokes : strokes2;
    // const stroke = index ? backs[`stroke${index + 1}`] : backs['stroke3'];
    return (
        <a
            className={'SocialMediaButton'}
            href={href}
            target={'_blank'}
            rel={'noopener noreferrer'}
        >
            <img
                alt={alt}
                className={svgClassName}
                src={src}
            />
        </a>
    );
};

export default SocialMediaButton;
