import React from 'react';
import SocialMediaButton from './SocialMediaButton/SocialMediaButton';
import Instagram from '../../icons/instagram.svg';
import YouTube from '../../icons/youtube.svg';
// import Facebook from '../../icons/facebook.svg';
import SoundCloud from '../../icons/soundcloud.svg';
import Mail from '../../icons/email.svg';
import Spotify from '../../music-icons/spotify.svg';

const BUTTONS = [
    {
        id: 'social-000',
        title: 'Spotify',
        url: 'mailto:podorva.official@gmail.com',
        imageSrc: Spotify,
        alt: 'Подорва Spotify',
        isWhite: true
    },
    {
        id: 'social-001',
        title: 'Instagram',
        url: 'https://www.instagram.com/podorva.band/',
        imageSrc: Instagram,
        alt: 'Подорва Instagram'
    },
    {
        id: 'social-002',
        title: 'YouTube',
        url: 'https://www.youtube.com/channel/UCKU4Hil835qDDL-u3n1kgWA',
        imageSrc: YouTube,
        alt: 'Подорва YouTube'
    },
    // {
    //     title: 'Facebook',
    //     url: 'https://www.facebook.com/podorva.official/',
    //     imageSrc: Facebook,
    //     alt: 'Подорва Facebook'
    // },
    {
        id: 'social-003',
        title: 'SoundCloud',
        url: 'https://soundcloud.com/podorva',
        imageSrc: SoundCloud,
        alt: 'Подорва SoundCloud'
    },
    {
        id: 'social-004',
        title: 'Mail',
        url: 'mailto:podorva.official@gmail.com',
        imageSrc: Mail,
    },
]


const Footer = () => {
    return (
        <div className={'Footer'}>
            {
                BUTTONS.map((button) => {
                    return (
                        <SocialMediaButton
                            key={button.id}
                            isWhite={button.isWhite}
                            id={button.id}
                            src={button.imageSrc}
                            href={button.url}
                            alt={button.alt}
                        />
                    )
                })
            }
        </div>
    );
};

export default Footer;
