import React from 'react';

const Page = ({
                  id,
                  children,
                  className,
                  style,
              }) => {
    return (
        <main
            id={id}
            style={style}
            className={className}
        >
            {children}
        </main>
    );
};

export default Page;
