import React, { useMemo } from 'react';
// import { Routes, Route, BrowserRouter } from 'react-router-dom';
import LisTitle from './images/lis.svg'
import OsinTitle from './images/osin.svg'
import OsinTown from './images/osin_town.svg'
import Page from './components/page/Page';
import SocialMediaButton from './components/footer/SocialMediaButton/SocialMediaButton';
import Spotify from './music-icons/spotify.svg';
import Deezer from './music-icons/deezer.svg';
import Amazon from './music-icons/amazon.svg';
import YouTubeMusic from './music-icons/youtubeMusic.svg';
import MainPage from './pages/MainPage';

// const LANG = 'ua';

export const STRINGS = {
    ua: {
        mainTitle: {
            title: 'Подорва',
            description: 'офіційна сторінка вашого улюбленого панк-гурту',
        }
    }
}

const BUTTONS_MUSIC = [
    {
        title: 'Spotify',
        color: 'green',
        url: 'https://open.spotify.com/album/5T0kSAo6kviPdlr0f9xfoG',
        imageSrc: Spotify,
    },
    {
        title: 'YouTube',
        color: 'red',
        url: 'https://music.youtube.com/watch?v=XIDrzG5XWqs',
        imageSrc: YouTubeMusic,
    },
    {
        title: 'Deezer',
        color: 'coral',
        url: 'https://deezer.page.link/r2NJEHZN9sy1qksY9',
        imageSrc: Deezer,
    },
    {
        title: 'Amazon',
        color: 'blue',
        url: 'https://music.amazon.com/tracks/B0CR4FK98M?marketplaceId=ATVPDKIKX0DER&musicTerritory=US&ref=dm_sh_HhDyiCukShYElruGGThdeTaZR',
        imageSrc: Amazon,
    },
    // {
    //     title: 'Tidal',
    //     color: 'grey',
    //     url: 'https://listen.tidal.com/track/320596403',
    //     imageSrc: Tidal,
    // },
]

const BUTTONS_MUSIC_OSIN = [
    {
        title: 'Spotify',
        color: 'green',
        url: 'https://open.spotify.com/album/51LOOUKXgbYD9wGvPoaIIG',
        imageSrc: Spotify,
    },
    {
        title: 'YouTube',
        color: 'red',
        url: 'https://music.youtube.com/watch?v=wClC0mrjwY8&si=gQ_sdI2z0w-h1irh',
        imageSrc: YouTubeMusic,
    },
    {
        title: 'Deezer',
        color: 'coral',
        url: 'https://deezer.page.link/VwG38NR3oc8zV1C88',
        imageSrc: Deezer,
    },
    {
        title: 'Amazon',
        color: 'blue',
        url: 'https://music.amazon.com/albums/B0CNFW72XH?marketplaceId=ATVPDKIKX0DER&musicTerritory=US&ref=dm_sh_JLOwmpu2Jvr7XbXavbiVuYveE',
        imageSrc: Amazon,
    },
    // {
    //     title: 'Tidal',
    //     color: 'grey',
    //     url: 'https://listen.tidal.com/track/320596403',
    //     imageSrc: Tidal,
    // },
]

const getReleasePageChildren = () => {
    return (
        <>
            <div className={'buttonsContainer lis'}>
                <img alt={'Подорва Ліс'} className={'releaseTitle'} src={LisTitle}/>
                {
                    BUTTONS_MUSIC.map((button, index) => {
                        return (
                            <SocialMediaButton
                                mainClassName={'SocialMediaButtonStroke'}
                                filter={`filter-${button.color}`}
                                // filter={`filter-yellow`}
                                key={`${index}-${button.title}`}
                                index={index}
                                title={button.title}
                                href={button.url}
                                src={button.imageSrc}
                            />
                        )
                    })
                }
            </div>
        </>
    );
};

const getReleasePageChildrenOsin = () => {
    return (
        <>
            <div className={'buttonsContainer osin'}>
                <img alt={'Подорва Осінь'} className={'releaseTitle osin'} src={OsinTitle}/>
                <img alt={'Подорва Осінь'} className={'releaseBackImg'} src={OsinTown}/>
                {
                    BUTTONS_MUSIC_OSIN.map((button, index) => {
                        return (
                            <SocialMediaButton
                                isWhite
                                mainClassName={'SocialMediaButtonStroke osin'}
                                filter={`filter-${button.color}`}
                                // filter={`filter-yellow`}
                                key={`${index}-${button.title}`}
                                index={index}
                                title={button.title}
                                href={button.url}
                                src={button.imageSrc}
                            />
                        )
                    })
                }
            </div>
        </>
    );
};

export const LisPage = () => {
    const childrenOne = useMemo(() => getReleasePageChildren(), []);
    return (
        <>
            <Page
                className={'Page one'}
                children={childrenOne}
            />
        </>
    );
}

export const OsinPage = () => {
    const childrenOne = useMemo(() => getReleasePageChildrenOsin(), []);
    return (
        <>
            <Page
                className={'Page one'}
                children={childrenOne}
            />
        </>
    );
}

function App() {
    return (
        <div className={'App'}>
            <MainPage />
        </div>
    );
}

export default App;
