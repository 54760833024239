import React from 'react';
import { STRINGS } from '../../App';

const MainTitle = ({ lang = 'ua' }) => {
  return (
    <div className={'TitleContainer'}>
      <h1 className={'Title'}>
          {STRINGS[lang]?.mainTitle?.title}
      </h1>
      <h2 className={'Info'}>
          {STRINGS[lang]?.mainTitle?.description}
      </h2>
    </div>
  );
};

export default MainTitle;
